import ui.AppVariant

enum class Variant(override val url: String, override val address: String?) : AppVariant {
    MainlandChina(
        "https://www.jetbrains.com.cn/en-us",
        "JetBrains Shanghai A51.35 / F, PingAn Riverfront Financial Center, 757 Mengzi Road, Huangpu District, Shanghai 200023, China") {
        override fun addLinks(addLink: (href: String, title: String) -> Unit) {
            addLink(
                "https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=%2031010102007601",
                "沪公网安备 31010102007601 号"
            )
            addLink(
                "https://beian.miit.gov.cn/",
                "沪 ICP 备 2021006743 号"
            )
        }
    }
}

suspend fun main() {
    launchApp {
        variant = Variant.MainlandChina
    }
}